import * as React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Paper,
  TextField,
} from "@material-ui/core";
import CampusForCurrentUserAutoComplete from "../../../components/AutoComplete/CampusForCurrentUserAutoComplete";
import { Autocomplete } from "@material-ui/lab";
import { Components, Components2, Components3 } from "./Components";
import { system } from "../../../constants/system/systemConstants";
import { getConfigSettingValue } from "../../../api/Common/configSettings";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      padding: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      height: "100%",
    },
    details: {
      padding: theme.spacing(2, 0.5),
      flex: "1 1",
    },
  })
);

const ReportsSetup: React.FC = () => {
  const [campusId, setCampusId] = React.useState<string>();
  const [reportType, setReportType] = React.useState<string>();
  const [report, setReport] = React.useState<string>();
  const classes = useStyles({});
  const [isIllinoisEnabled, setIsIllinoisEnabled] =
    React.useState<boolean>(false);

  const getIllinoisSetting = async () => {
    if (campusId) {
      try {
        const res = await getConfigSettingValue(
          system.settings.enableIllinoisTranscript,
          campusId
        );
        setIsIllinoisEnabled(
          ((res?.data as string) || "no").toLowerCase() === "yes"
        );
      } catch (e) {
        setIsIllinoisEnabled(false);
      }
    }
  };

  React.useEffect(() => {
    getIllinoisSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campusId]);

  return (
    <Paper className={classes.root}>
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid xs={3} item>
            <CampusForCurrentUserAutoComplete
              valueFilter={
                campusId
                  ? {
                      key: "value",
                      values: [campusId],
                    }
                  : undefined
              }
              filterHandle={(v: any) => {
                if (v && v.value) {
                  setCampusId(v.value);
                } else {
                  setCampusId(undefined);
                  setReportType(undefined);
                }
              }}
            />
          </Grid>
          <Grid xs={3} item>
            <Autocomplete
              onChange={(e: any, v: any) => {
                if (e.type !== "keydown") {
                  if (v) {
                    setReportType(v);
                  } else {
                    setReportType(undefined);
                  }
                }
              }}
              options={(campusId && ["Student"]) || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  fullWidth
                  label="ReportType"
                />
              )}
            />
          </Grid>
          <Grid xs={3} item>
            <Autocomplete
              onChange={(e: any, v: any) => {
                if (e.type !== "keydown") {
                  if (v) {
                    setReport(v);
                  } else {
                    setReport(undefined);
                  }
                }
              }}
              options={[
                reportType && isIllinoisEnabled && "Illinois Transcript Report",
                reportType && "Transcript",
                reportType === "Student" && "Progress",
              ].filter(Boolean)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  fullWidth
                  label="Report"
                />
              )}
            />
          </Grid>
        </Grid>
        <div className={classes.details}>
          {report &&
            (report == "Illinois Transcript Report" ? (
              <Components reportName={report} componentsProps={{ campusId }} />
            ) : report && report !== "Progress" ? 
            (
              <Components2 reportName={report} componentsProps={{ campusId }} />
            ) : (
              <Components3 reportName={report} componentsProps={{ campusId }} />
            ))}
        </div>
      </div>
    </Paper>
  );
};

export default ReportsSetup;
